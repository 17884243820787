import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Input,
  Upload,
  Button,
  Radio,
  Select,
  Modal,
  Table,
} from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeDetails,
  employeeList,
  holidays,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../layout/Header";

// Constants for leave specifications - match exactly with backend
const LEAVE_SPECS = {
  FIRST_HALF: "First half",
  SECOND_HALF: "Second half",
  FULL_DAY: "Full day",
};

const LeaveformRS = () => {
  const { Option } = Select;
  const [reason, setReason] = useState("");
  const [fileList, setFileList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [employeeID, setEmployeeID] = useState(null);
  const [contactPersonID, setContactPersonID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [employeeListData, setEmployeeListData] = useState([]);
  const [isRSRD, setIsRSRD] = useState(false);

  // New states
  const [isMultiDay, setIsMultiDay] = useState(false);
  const [dailyLeaveDetails, setDailyLeaveDetails] = useState([]);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  // Add holidayDates state
  const [holidayDates, setHolidayDates] = useState([]);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
        if (
          response.data.employment_details[0].designation_detail.name ===
            "MD" ||
          response.data.employment_details[0].designation_detail.name === "CEO"
        ) {
          setIsRSRD(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get(employeeList, {
          params: { employee_name: searchTerm },
        });
        setEmployeeListData(response.data);
      } catch (error) {
        console.error("Error fetching employee list:", error);
      }
    };

    if (searchTerm) {
      fetchEmployees();
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    try {
      const response = await axiosInstance.get(`${holidays}`);
      const uniqueDates = [
        ...new Set(
          response.data.map((holiday) =>
            moment(holiday.date).format("YYYY-MM-DD")
          )
        ),
      ];
      console.log(uniqueDates, "Unique Holiday Dates");
      setHolidayDates(uniqueDates);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList.slice(-1));
  };

  const calculateTotalDays = () => {
    if (dailyLeaveDetails.length === 0) return 0;

    return dailyLeaveDetails.reduce((total, detail) => {
      switch (detail.leave_specification) {
        case LEAVE_SPECS.FIRST_HALF:
        case LEAVE_SPECS.SECOND_HALF:
          return total + 0.5;
        case LEAVE_SPECS.FULL_DAY:
          return total + 1;
        default:
          return total;
      }
    }, 0);
  };

  // Separate function for calculating leave details
  const calculateLeaveDetails = (start, end) => {
    let details = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      const formattedDate = moment(currentDate).format("YYYY-MM-DD");
      if (
        currentDate.getDay() !== 0 && // Sunday
        currentDate.getDay() !== 6 && // Saturday
        !holidayDates.includes(formattedDate) // Holiday check
      ) {
        details.push({
          date: formattedDate,
          leave_specification: "Full day",
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return details;
  };

  // Update handleDateRangeSelect to separate concerns
  const handleDateRangeSelect = (dates) => {
    if (!dates) {
      setStartDate(null);
      setEndDate(null);
      setTotalDays(0);
      setDailyLeaveDetails([]);
      return;
    }

    if (isMultiDay) {
      const [start, end] = dates;
      if (!start || !end) {
        setStartDate(start || null);
        setEndDate(null);
        setTotalDays(0);
        setDailyLeaveDetails([]);
        return;
      }

      // First, update the date states
      setStartDate(start);
      setEndDate(end);

      // Then, calculate leave details separately
      const details = calculateLeaveDetails(start, end);
      setDailyLeaveDetails(details);
      setTotalDays(details.length);
    } else {
      // Single day selection
      setStartDate(dates);
      setEndDate(dates);

      const details = calculateLeaveDetails(dates, dates);
      setDailyLeaveDetails(details);
      setTotalDays(1);
    }
  };

  const handleDailyLeaveChange = (date, specification) => {
    setDailyLeaveDetails((prev) => {
      const newDetails = [...prev];
      const index = newDetails.findIndex((x) => x.date === date);
      if (index >= 0) {
        newDetails[index].leave_specification = specification;
      }
      return newDetails;
    });

    // Debug log
    console.log("Leave specification changed:", { date, specification });

    // Recalculate total days after state update
    setTimeout(() => {
      setTotalDays(calculateTotalDays());
    }, 0);
  };

  const validateForm = () => {
    if (!reason.trim()) {
      toast.error("Reason is required");
      return false;
    }
    if (!startDate) {
      toast.error("Start date is required");
      return false;
    }
    if (isMultiDay && !endDate) {
      toast.error("End date is required");
      return false;
    }
    if (dailyLeaveDetails.length === 0) {
      toast.error("Leave details are required");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      // Create FormData for the file
      const formData = new FormData();
      if (fileList.length > 0) {
        formData.append("document", fileList[0].originFileObj);
      }

      const currentTimestamp = moment().format("YYYY-MM-DD");

      // Create the JSON payload for other data
      const jsonPayload = {
        user: employeeID,
        reason: reason,
        start_date: dailyLeaveDetails[0].date,
        end_date: dailyLeaveDetails[dailyLeaveDetails.length - 1].date,
        total_days: totalDays,
        leave_details: dailyLeaveDetails.map((detail) => ({
          date: detail.date,
          leave_specification: detail.leave_specification,
        })),
        // contact_person: employeeID,
        // approved_by: employeeID,
        status: "Approved",
        approved_at: currentTimestamp,
      };

      // First, send the JSON data
      const jsonResponse = await axiosInstance.post(addLeaves, jsonPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // If there's a file, send it as a separate request
      if (fileList.length > 0) {
        await axiosInstance.patch(
          `${addLeaves}${jsonResponse.data.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      toast.success("Leave application submitted successfully");
      // Reset form
      setReason("");
      setStartDate(null);
      setEndDate(null);
      setTotalDays(0);
      setFileList([]);
      setDailyLeaveDetails([]);
    } catch (error) {
      console.error("Submit Error:", error.response?.data || error);
      toast.error("Error submitting leave application");
    }
  };

  const handleModeChange = (e) => {
    const isMulti = e.target.value === "multi";
    setIsMultiDay(isMulti);

    // Reset states when switching modes
    setStartDate(null);
    setEndDate(null);
    setTotalDays(0);
    setDailyLeaveDetails([]);
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header text-center">
              <h2 className="text-3xl font-bold text-gray-700">
                Leave Application Form
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="leave-application-form">
                    <form>
                      <div className="form-item mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Reason for Leave
                        </label>
                        <Input.TextArea
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          rows={3}
                          placeholder="Please provide your reason for leave"
                        />
                      </div>

                      <div className="form-item mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Leave Duration
                        </label>
                        <Radio.Group
                          value={isMultiDay ? "multi" : "single"}
                          onChange={handleModeChange}
                        >
                          <Radio value="single">Single Day</Radio>
                          <Radio value="multi">Multi Day</Radio>
                        </Radio.Group>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-item mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">
                              Select Date{isMultiDay ? " Range" : ""}
                            </label>
                            {isMultiDay ? (
                              <DatePicker.RangePicker
                                onChange={handleDateRangeSelect}
                                format="YYYY-MM-DD"
                                disabledDate={(current) => {
                                  // Can't select days before today
                                  if (
                                    current &&
                                    current < moment().startOf("day")
                                  ) {
                                    return true;
                                  }
                                  // Disable weekends and holidays
                                  return (
                                    current &&
                                    (current.day() === 0 ||
                                      current.day() === 6 ||
                                      holidayDates.includes(
                                        current.format("YYYY-MM-DD")
                                      ))
                                  );
                                }}
                                value={[startDate, endDate]}
                                className="w-full"
                                placeholder={["Start Date", "End Date"]}
                              />
                            ) : (
                              <DatePicker
                                onChange={handleDateRangeSelect}
                                format="YYYY-MM-DD"
                                disabledDate={(current) => {
                                  // Can't select days before today
                                  if (
                                    current &&
                                    current < moment().startOf("day")
                                  ) {
                                    return true;
                                  }
                                  // Disable weekends and holidays
                                  return (
                                    current &&
                                    (current.day() === 0 ||
                                      current.day() === 6 ||
                                      holidayDates.includes(
                                        current.format("YYYY-MM-DD")
                                      ))
                                  );
                                }}
                                value={startDate}
                                className="w-full"
                                placeholder="Select Date"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-item mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">
                              Total Working Days
                            </label>
                            <Input
                              value={totalDays}
                              readOnly
                              disabled
                              suffix="day(s)"
                              style={{ width: "200px" }}
                              className="bg-gray-100 cursor-not-allowed"
                            />
                          </div>
                        </div>
                      </div>

                      {dailyLeaveDetails.length > 0 && (
                        <div className="form-item mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">
                            Daily Leave Details
                          </label>
                          <div className="daily-leave-table">
                            <Table
                              dataSource={dailyLeaveDetails}
                              columns={[
                                {
                                  title: "Date",
                                  dataIndex: "date",
                                  key: "date",
                                  width: "20%",
                                  render: (date) =>
                                    moment(date).format("DD MMM YYYY"),
                                },
                                {
                                  title: "Leave Type",
                                  dataIndex: "leave_specification",
                                  key: "leave_specification",
                                  width: "80%",
                                  render: (text, record) => (
                                    <Radio.Group
                                      value={text}
                                      onChange={(e) =>
                                        handleDailyLeaveChange(
                                          record.date,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <Radio value={LEAVE_SPECS.FIRST_HALF}>
                                        First Half (8:30am - 1:30pm)
                                      </Radio>
                                      <Radio value={LEAVE_SPECS.SECOND_HALF}>
                                        Second Half (1:30pm - 6:30pm)
                                      </Radio>
                                      <Radio value={LEAVE_SPECS.FULL_DAY}>
                                        Full Day
                                      </Radio>
                                    </Radio.Group>
                                  ),
                                },
                              ]}
                              pagination={false}
                              rowKey={(record) => record.date}
                              scroll={{ y: 240 }}
                              className="daily-leave-details-table"
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-item mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Upload Document (optional)
                        </label>
                        <Upload
                          fileList={fileList}
                          onChange={handleUploadChange}
                          beforeUpload={() => false}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      </div>

                      <Button type="primary" onClick={handleSubmit}>
                        Submit Application
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveformRS;
