import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Form as AntForm,
  Input,
  DatePicker as AntDatePicker,
  Tabs,
  Space,
  Select,
} from "antd";
import { Button, Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addTaskGacl,
  employeeList,
  employeeDetails,
  assignedTaskGacl,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Add Form.Item from antd
const { Item: FormItem } = AntForm;

const TaskManagementList = () => {
  // State variables
  const [show, setShow] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [taskDetails, setTaskDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, setActiveTab] = useState("all");
  const [url, setUrl] = useState("");
  const [taskTypeFilter, setTaskTypeFilter] = useState("all");

  // Form states
  const [taskType, setTaskType] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // Tab items configuration
  const tabItems = [
    {
      key: "all",
      label: "All Tasks",
    },
    {
      key: "pending",
      label: "Pending",
    },
    {
      key: "approved",
      label: "Approved",
    },
    {
      key: "rejected",
      label: "Rejected",
    },
    {
      key: "completed",
      label: "Completed",
    },
  ];

  // Add task type options
  const taskTypeOptions = [
    { value: "all", label: "All Types" },
    { value: "ESR", label: "ESR" },
    { value: "AML", label: "AML" },
    { value: "Internal Audit", label: "Internal Audit" },
    { value: "External Audit", label: "External Audit" },
    { value: "Personal Income Tax", label: "Personal Income Tax" },
    { value: "Remittance", label: "Remittance" },
  ];

  // Column definitions
  const columns = [
    //sr no
    {
      title: "Sr No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Task",
      dataIndex: ["task_details", "task"],
      key: "task",
    },
    {
      title: "Assignee",
      dataIndex: ["assignee_details", "employee_name"],
      key: "assignee",
    },
    {
      title: "Due Date",
      dataIndex: ["task_details", "due_date"],
      key: "due_date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span className={`badge ${getStatusBadgeClass(status)}`}>{status}</span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleViewDetails(record)}
          >
            View Details
          </Button>
          {record.status === "Pending" && (
            <>
              <Button
                variant="outline-success"
                size="sm"
                onClick={() => handleApprove(record)}
                disabled={isLoadingAction}
              >
                Approve
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => handleReject(record)}
                disabled={isLoadingAction}
              >
                Reject
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  // Fetch employee details for current user
  const fetchEmployeeDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeID(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Error fetching employee details");
    }
  }, []);

  // Fetch all employees for assignee dropdown
  const fetchEmployees = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${employeeList}?department=2`);
      if (response.data && response.data) {
        setEmployees(response.data);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employees list");
    }
  }, []);

  // Update fetchTasks to handle loading state better
  const fetchTasks = useCallback(
    async (page = 1) => {
      try {
        setIsLoading(true);

        let url = `${assignedTaskGacl}?page=${page}&page_size=${pageSize}`;

        // Add filter based on active tab
        switch (activeTab) {
          case "completed":
            url += "&task_details__is_done=true";
            break;
          case "pending":
            url += "&status=Pending";
            break;
          case "approved":
            url += "&status=Approved";
            break;
          case "rejected":
            url += "&status=Rejected";
            break;
          default:
            break;
        }

        // Add task type filter
        if (taskTypeFilter !== "all") {
          url += `&task_type=${taskTypeFilter}`;
        }

        const response = await axiosInstance.get(url);
        setDataSource(response.data.results || []);
        setTotalCount(response.data.count || 0);
      } catch (error) {
        console.error("Error fetching tasks:", error);
        toast.error("Error fetching tasks");
        setDataSource([]);
        setTotalCount(0);
      } finally {
        setIsLoading(false);
      }
    },
    [activeTab, pageSize, taskTypeFilter]
  );

  // Update tab change handler to be more robust
  const handleTabChange = (key) => {
    // Clear existing data first
    setDataSource([]);
    setTotalCount(0);
    setCurrentPage(1);

    // Update active tab
    setActiveTab(key);
  };

  // Add effect to handle data fetching when tab changes
  useEffect(() => {
    fetchTasks(1);
  }, [activeTab, fetchTasks]);

  // Update pagination change handler
  const handlePaginationChange = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      fetchTasks(page);
    }
  };

  // Update initial fetch
  useEffect(() => {
    fetchEmployeeDetails();
    fetchEmployees();
    fetchTasks(1);
  }, [fetchEmployeeDetails, fetchEmployees, fetchTasks]);

  const handleClose = () => {
    setShow(false);
    setSelectedRecord(null);
    setTaskType("");
    setSelectedAssignee("");
    setDueDate(null);
    setUrl("");
    setSelectedFile(null);
  };

  const handleSave = async () => {
    if (!taskType || !selectedAssignee || !dueDate) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("task", taskType);
      formData.append("user", employeeID);
      formData.append("assignee", selectedAssignee);
      formData.append("due_date", moment(dueDate).format("YYYY-MM-DD"));

      if (selectedFile) {
        formData.append("file", selectedFile);
      }
      if (url) {
        formData.append("url", url);
      }
      let response;
      if (selectedRecord) {
        response = await axiosInstance.patch(
          `${addTaskGacl}${selectedRecord.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Task updated successfully!");
      } else {
        response = await axiosInstance.post(addTaskGacl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Create Response:", response.data);
        toast.success("Task created successfully!");
      }

      await fetchTasks();
      handleClose();
    } catch (error) {
      console.error("Error saving task:", error);
      toast.error(
        error.response?.data?.message || "Error saving task. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (record) => {
    setIsLoadingAction(true);
    try {
      await axiosInstance.patch(`${assignedTaskGacl}${record.id}/`, {
        status: "Approved",
      });
      toast.success("Task approved successfully!");
      fetchTasks(currentPage);
    } catch (error) {
      console.error("Error approving task:", error);
      toast.error(error.response?.data?.message || "Error approving task");
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleReject = async (record) => {
    setIsLoadingAction(true);
    try {
      // Update status in assignedTaskGacl
      await axiosInstance.patch(`${assignedTaskGacl}${record.id}/`, {
        status: "Rejected",
      });

      // Update is_done in addTaskGacl
      await axiosInstance.patch(`${addTaskGacl}${record.task_details.id}/`, {
        is_done: false,
      });

      toast.success("Task rejected successfully!");
      fetchTasks(currentPage);
    } catch (error) {
      console.error("Error rejecting task:", error);
      toast.error(error.response?.data?.message || "Error rejecting task");
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleViewDetails = (record) => {
    const taskDetails = {
      task: record.task_details.task,
      assignee: record.assignee_details.employee_name,
      dueDate: record.task_details.due_date,
      status: record.status,
      taskFile: record.task_details.file,
      submissionFile: record.file,
      comments: record.comments,
      createdAt: record.created,
      acceptedTime: record.task_details.accepted_time,
      finishedTime: record.task_details.finished_time,
      url: record.task_details.url,
    };
    setTaskDetails(taskDetails);
    setShowDetailsModal(true);
  };

  // Add this helper function
  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Approved":
        return "bg-success";
      case "Rejected":
        return "bg-danger";
      case "Pending":
        return "bg-warning";
      default:
        return "bg-secondary";
    }
  };

  // Add handler for task type filter change
  const handleTaskTypeFilterChange = (value) => {
    setTaskTypeFilter(value);
    setCurrentPage(1);
    setDataSource([]);
  };

  return (
    <>
      <div className="row mb-4 align-items-center">
        <div className="col-auto">
          <Button
            variant="success"
            onClick={() => {
              setShow(true);
              setSelectedRecord(null);
            }}
          >
            Add Task
          </Button>
        </div>
        <div className="col-auto">
          <AntForm.Item label="Task Type" className="mb-0">
            <Select
              value={taskTypeFilter}
              onChange={handleTaskTypeFilterChange}
              style={{ width: 200 }}
              options={taskTypeOptions}
              dropdownMatchSelectWidth={false}
            />
          </AntForm.Item>
        </div>
        <div className="col d-flex justify-content-end">
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            items={tabItems}
            className="task-management-tabs"
            size="middle"
            tabBarGutter={24}
          />
        </div>
      </div>

      <div className="table-responsive">
        <Table
          className="table-striped"
          columns={columns}
          dataSource={dataSource}
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalCount,
            onChange: handlePaginationChange,
            showTotal: (total, range) =>
              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          }}
          locale={{
            emptyText: isLoading ? "Loading..." : "No data available",
          }}
        />
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedRecord ? "Edit Task" : "Add Task"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Task Type</Form.Label>
              <Form.Control
                as="select"
                value={taskType}
                onChange={(e) => setTaskType(e.target.value)}
                required
              >
                <option value="">Select task type</option>
                <option value="ESR">ESR</option>
                <option value="AML">AML</option>
                <option value="Internal Audit">Internal Audit</option>
                <option value="External Audit">External Audit</option>
                <option value="Personal Income Tax">Personal Income Tax</option>
                <option value="Remittance">Remittance</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Assignee</Form.Label>
              <Form.Control
                as="select"
                value={selectedAssignee}
                onChange={(e) => setSelectedAssignee(e.target.value)}
              >
                <option value="">Select an assignee</option>
                {employees &&
                  employees.length > 0 &&
                  employees.map((emp) => (
                    <option key={emp.id} value={emp.id}>
                      {emp.employee_name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Due Date</Form.Label>
              {selectedRecord ? (
                <DatePicker
                  selected={dueDate ? new Date(dueDate) : null}
                  onChange={(date) =>
                    setDueDate(moment(date).format("YYYY-MM-DD"))
                  }
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  minDate={new Date()}
                />
              ) : (
                <AntDatePicker
                  style={{ width: "100%" }}
                  value={dueDate ? moment(dueDate, "YYYY-MM-DD") : null}
                  onChange={(date, dateString) => setDueDate(dateString)}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                />
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter a URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Upload File</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file && file.size > 5 * 1024 * 1024) {
                    // 5MB limit
                    toast.warning("File size should not exceed 5MB");
                    e.target.value = null;
                    return;
                  }
                  setSelectedFile(file);
                }}
              />
              <small className="text-muted">Maximum file size: 5MB</small>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isLoading}>
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" />
                Saving...
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Details Modal */}
      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Task Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingDetails ? (
            <div className="text-center">Loading details...</div>
          ) : taskDetails ? (
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <FormItem label="Task Type">
                    <Input value={taskDetails.task} disabled />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <FormItem label="Status">
                    <Input value={taskDetails.status} disabled />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <FormItem label="Assigned By">
                    <Input value={taskDetails.assignee} disabled />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <FormItem label="Due Date">
                    <Input
                      value={moment(taskDetails.dueDate).format("DD-MM-YYYY")}
                      disabled
                    />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <FormItem label="Accepted Time">
                    <Input
                      value={
                        taskDetails.acceptedTime
                          ? moment(taskDetails.acceptedTime).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : "-"
                      }
                      disabled
                    />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <FormItem label="Finished Time">
                    <Input
                      value={
                        taskDetails.finishedTime
                          ? moment(taskDetails.finishedTime).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : "-"
                      }
                      disabled
                    />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <FormItem label="Time Taken to Complete">
                    <Input
                      value={
                        taskDetails.finishedTime && taskDetails.acceptedTime
                          ? (() => {
                              const duration = moment.duration(
                                moment(taskDetails.finishedTime).diff(
                                  moment(taskDetails.acceptedTime)
                                )
                              );
                              const days = duration.days();
                              const hours = duration.hours();
                              const minutes = duration.minutes();
                              const seconds = duration.seconds();

                              return `${
                                days ? days + "d " : ""
                              }${hours}h ${minutes}m ${seconds}s`;
                            })()
                          : "-"
                      }
                      disabled
                    />
                  </FormItem>
                </div>
                <div className="col-12">
                  <FormItem label="Task File">
                    {taskDetails.taskFile ? (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() =>
                          window.open(taskDetails.taskFile, "_blank")
                        }
                      >
                        <i className="fas fa-file-download me-2"></i>
                        View Task File
                      </Button>
                    ) : (
                      "-"
                    )}
                  </FormItem>
                </div>
                <div className="col-12">
                  <FormItem label="Submission File">
                    {taskDetails.submissionFile ? (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() =>
                          window.open(taskDetails.submissionFile, "_blank")
                        }
                      >
                        <i className="fas fa-file-download me-2"></i>
                        View Submission File
                      </Button>
                    ) : (
                      "-"
                    )}
                  </FormItem>
                </div>
                <div className="col-12">
                  <FormItem label="Comments">
                    <Input.TextArea
                      value={taskDetails.comments || "-"}
                      disabled
                      rows={4}
                    />
                  </FormItem>
                </div>
              </div>
            </Form>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDetailsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskManagementList;
