import React from "react";
import TaskManagementList from "./TaskManagement";
import Header from "../../../layout/Header";
import GaclSideBar from "../../../layout/GaclSidebar";

const GaclTaskManagement = () => {
  return (
    <>
      <Header />
      <GaclSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4">
            <div className="col-12">
              <h1 className="text-2xl font-bold">Task Assignment</h1>
            </div>
          </div>
          <TaskManagementList />
        </div>
      </div>
    </>
  );
};

export default GaclTaskManagement;
