export const baseURL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://habot-hr-gacl-dc-30042023.uc.r.appspot.com/";

// Accounts endpoints
export const signUpUrl = "accounts/sign-up/";
export const googleLoginUrl = "accounts/o/google-oauth2/";
export const loginUrl = "accounts/login/";
export const logoutUrl = "accounts/logout/";
export const clientProfileUrl = "accounts/client-profile/";
export const vendorProfileUrl = "accounts/vendor-profile/";
export const verifyOTPUrl = "accounts/verify-otp-token/";
export const refreshTokenUrl = "accounts/token/refresh/";
export const forgotPasswordUrl = "accounts/reset_password/";
export const resetPasswordUrl = "accounts/reset_password_confirm/";
export const UserUrl = "accounts/users/";
export const changePassUrl = "accounts/set_password/";
export const resendOTPUrl = "accounts/resend-otp-token/";
export const vendorGallery = "accounts/vendor-gallery/";
export const vendorVerification = "accounts/vendor-verification-request/";
export const departmentList = "accounts/department/";
export const designationList = "accounts/designation/";
export const createUser = "accounts/create-user/";
export const basicUserDetails = (Userid) =>
  `accounts/basic-user-details/${Userid}/`; //will receive and id
export const employeeDetails = "accounts/employee/";
export const employeeList = "accounts/users/";
export const employmentDetail = "accounts/employment-detail/";

// employee endpoints
export const uploadDocuments = "employee/upload-document/";
export const documentList = "employee/document-list/";
export const namingList = "employee/naming-list/";
export const documentDetail = "employee/document-detail/"; //will receive and id
export const nameShareApi = "employee/name-share/"; //will receive and id

//employee QPA
export const employeeQPA = "employee/qpa/";
export const QPAform = "employee/qpa-form/";
export const nomination = "employee/nomination/";

// hr endpoints
export const ziitopic = "hr/zii-topic/";
export const addZii = "employee/add-zii/";
export const getZii = (userId, monthName, weekNumber, year) =>
  `employee/add-zii/?user=${userId}&month_name=${monthName}&week_number=${weekNumber}&year=${year}`;
export const patchZii = (ziiID) => `employee/zii/${ziiID}/`;
export const addAppraisal = (
  reportee,
  supervisor,
  year,
  monthName,
  weekNumber
) =>
  `employee/weekly-appraisal/?reportee=${reportee}&supervisor=${supervisor}&week=${weekNumber}&year=${year}&month=${monthName}`;
export const getMonthAppraisal = (reportee, year, monthName) =>
  `employee/weekly-appraisal/?reportee=${reportee}&month=${monthName}&year=${year}`;
export const companyAveragebyWeek = (weekNumber, year, monthName) =>
  `employee/weekly-appraisal-average/${weekNumber}/${monthName}/${year}/`;
export const ziiReport = (yearNumber, monthNumber) =>
  `hr/zii-scores/${yearNumber}/${monthNumber}/`;
export const addLeaves = "employee/leaves/";
export const hrCourses = "hr/internal-courses/";
export const hrCourseById = (userId) => `hr/internal-courses/${userId}/`;
export const departmentAppraisal = (department, year, monthName) =>
  `hr/appraisal-report/?department=${department}&month=${monthName}&year=${year}`;
export const employeeNotifications = "employee/notifications/";
export const patchNotifications = (ID) => `employee/notifications/${ID}/`;
export const enrolledCourses = "employee/course-completion/";
export const patchEnrolledCourses = (ID) => `employee/course-completion/${ID}/`;
export const deleteEnrolledCertificate = (ID) =>
  `employee/course-completion/${ID}/delete-certificate/`;
export const trainingDashboard = "hr/training-dashboard/";
export const holidays = "hr/holidays/";
export const celebrations = "hr/celebrations/";

//attendance endpoints
export const attendance = "hr/attendances/";
export const clockIn = "hr/attendances/start_day/";
export const clockOut = (ID) => `hr/attendances/${ID}/end_day/`;
export const clockStatus = (ID) => `hr/attendances/${ID}/status_update/`;

//habot policy endpoints
export const policyDashboard = "hr/policy-dashboard/";
export const addPolicy = "hr/policies/";
export const policyAcknowledgement = "hr/policy-acknowledgement/";
export const policyFeedback = "hr/policy-feedbacks/";

//technical endpoints
export const trackInitialisation = "technical/trackinitialisation/";
export const subtracklist = "technical/subtracklist/";
export const subtrackAssignee = "technical/subtrackassignee/";
export const trackJobassignments = "technical/trackjobassignments/";

export const projectManagement = "technical/project-management/";
export const projects = "technical/projects/";

//operations endpoints
export const trackInitialization = "operations/track-initialization/";
export const assignedJob = "operations/assigned-job/";
export const endDocument = "operations/end-document/";
export const endDocumentField = "operations/end-field/";
export const predecessorElement = "operations/predecessor-element/";
export const predecessorField = "operations/predecessor-field/";
export const summaryField = "operations/track-summary/";

//Accounts endpoints
export const addTaskGacl = "gacl/add-task/";
export const assignedTaskGacl = "gacl/add-esr/";
export const gaclBoardMeeting = "gacl/board-meeting/";
export const companyDetailsList = "gacl/company-details/";
