import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  employmentDetail,
  departmentList,
  designationList,
  employeeList,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const EmploymentInfo = () => {
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeBasicData, setEmployeeBasicData] = useState(null);

  const employmentTypes = [
    "Full Time",
    "Part Time",
    "Contract",
    "Internship",
    "Other",
  ];
  const employmentStatuses = ["Active", "Inactive", "Terminated"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [empDetail, deptList, desList, empList] = await Promise.all([
          axiosInstance.get(`${employmentDetail}${id}/`),
          axiosInstance.get(departmentList),
          axiosInstance.get(designationList),
          axiosInstance.get(employeeList),
        ]);

        setEmployeeData(empDetail.data);
        setDepartments(deptList.data);
        setDesignations(desList.data);
        setEmployees(empList.data);
        const employee = empList.data.find(
          (emp) => emp.id === empDetail.data.employee_detail
        );
        if (employee) {
          setEmployeeName(employee.employee_name);
          setEmployeeBasicData(employee);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData({
      ...employeeData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      const hasDateOfBirthChanged =
        employeeBasicData?.date_of_birth !==
        employees.find((emp) => emp.id === employeeBasicData?.id)
          ?.date_of_birth;

      const updatePromises = [];

      updatePromises.push(
        axiosInstance.patch(`${employmentDetail}${id}/`, employeeData)
      );

      if (hasDateOfBirthChanged && employeeBasicData?.id) {
        updatePromises.push(
          axiosInstance.patch(`${employeeList}${employeeBasicData.id}/`, {
            date_of_birth: employeeBasicData.date_of_birth,
          })
        );
      }

      const [employmentResponse, dobResponse] = await Promise.all(
        updatePromises
      );

      setEmployeeData(employmentResponse.data);
      if (dobResponse) {
        setEmployeeBasicData(dobResponse.data);
      }

      toast.success(
        hasDateOfBirthChanged
          ? "Employee information and date of birth updated successfully!"
          : "Employee information updated successfully!"
      );
    } catch (error) {
      console.error("Error updating information:", error);
      toast.error("Failed to update employee information.");
    }
  };

  const handleDobChange = (e) => {
    setEmployeeBasicData({
      ...employeeBasicData,
      date_of_birth: e.target.value,
    });
  };

  if (isLoading || !employeeData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <h2 className="mb-4">Edit Employment Details</h2>
          <Card className="p-4 shadow-sm">
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="employeeName" className="mb-3">
                    <Form.Label>Employee Name</Form.Label>
                    <Form.Control type="text" value={employeeName} readOnly />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="dateOfBirth">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      value={employeeBasicData?.date_of_birth || ""}
                      onChange={handleDobChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="employmentType">
                    <Form.Label>Employment Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="employment_type"
                      value={employeeData.employment_type}
                      onChange={handleInputChange}
                    >
                      {employmentTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="employmentStatus">
                    <Form.Label>Employment Status</Form.Label>
                    <Form.Control
                      as="select"
                      name="employment_status"
                      value={employeeData.employment_status}
                      onChange={handleInputChange}
                    >
                      {employmentStatuses.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="designation">
                    <Form.Label>Designation</Form.Label>
                    <Form.Control
                      as="select"
                      name="designation"
                      value={employeeData.designation || ""}
                      onChange={handleInputChange}
                    >
                      <option value="">Select a designation</option>
                      {designations.map((des) => (
                        <option key={des.id} value={des.id}>
                          {des.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="reportingTo">
                    <Form.Label>Reporting To</Form.Label>
                    <Form.Control
                      as="select"
                      name="reporting_to"
                      value={employeeData.reporting_to || ""}
                      onChange={handleInputChange}
                    >
                      <option value="">Select a person</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.employee_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="department">
                    <Form.Label>Department</Form.Label>
                    <Form.Control
                      as="select"
                      name="department"
                      value={employeeData.department || ""}
                      onChange={handleInputChange}
                    >
                      <option value="">Select a department</option>
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="effectiveDate">
                    <Form.Label>Effective Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="effective_date"
                      value={employeeData.effective_date || ""}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="probationPeriod">
                    <Form.Label>Probation Period (in months)</Form.Label>
                    <Form.Control
                      type="number"
                      name="probation_period"
                      value={employeeData.probation_period || ""}
                      onChange={handleInputChange}
                      placeholder="Enter probation period in months"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <Form.Group controlId="comments">
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="comments"
                      value={employeeData.comments || ""}
                      onChange={handleInputChange}
                      placeholder="Enter any comments"
                      rows={3}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="primary" className="mt-4" onClick={handleSave}>
                Save All Changes
              </Button>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default EmploymentInfo;
