import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  employeeNotifications,
  patchNotifications,
} from "../../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import Header from "../../../../layout/Header";
import GaclSideBar from "../../../../layout/GaclSidebar";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { ArrowRightCircle } from "react-feather";

const GACLDashboard = () => {
  const [gaclNotifications, setGaclNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch notifications and filter in frontend
  useEffect(() => {
    const fetchGaclNotifications = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `${employeeNotifications}?is_read=false&department=gacl`
        );
        const filteredNotifications = response.data.slice(0, 20);
        setGaclNotifications(filteredNotifications);
      } catch (error) {
        console.error("Error fetching GACL notifications:", error);
        toast.error("Error fetching notifications");
      } finally {
        setIsLoading(false);
      }
    };

    fetchGaclNotifications();
  }, []);

  const markAsRead = (id) => {
    axiosInstance
      .patch(patchNotifications(id), { is_read: true })
      .then((response) => {
        const updatedUnreadNotifications = gaclNotifications.filter(
          (notification) => notification.id !== id
        );
        const movedNotification = gaclNotifications.find(
          (notification) => notification.id === id
        );
        setGaclNotifications(updatedUnreadNotifications);
        setReadNotifications([movedNotification, ...readNotifications]);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error updating notification");
      });
  };

  return (
    <>
      <div>
        <Header />
        <GaclSideBar />

        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              {/* Page Header */}
              <Breadcrumbs maintitle="Welcome Accounts!" />

              {/* Update Message */}
              <div className="row">
                <div className="col-md-12">
                  <div className="employee-alert-box">
                    <div className="alert alert-outline-success alert-dismissible fade show">
                      <div className="employee-alert-request">
                        <i className="far fa-circle-question" />
                        Portal is on an updation and all the features will be
                        added later!!!
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      >
                        <i className="fas fa-xmark" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Content Row */}
              <div className="row">
                {/* Left Side - Empty for now */}
                <div className="col-md-8">{/* Space for future content */}</div>

                {/* Right Side Notifications */}
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="statistic-header">
                        <h4>Important</h4>
                        <div className="important-notification">
                          <Link to="/gacl-task-assigned">
                            <span className="me-1">View All</span>
                            <ArrowRightCircle size={15} />
                          </Link>
                        </div>
                      </div>
                      <div className="notification-tab">
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#unread_notification_tab"
                            >
                              <i className="la la-bell" /> Unread
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#read_notification_tab"
                            >
                              <i className="la la-check-circle" /> Read
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="unread_notification_tab"
                          >
                            <div
                              className="employee-noti-content"
                              style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                              <ul className="employee-notification-list">
                                {gaclNotifications.length > 0 ? (
                                  gaclNotifications.map((notification) => (
                                    <NotificationItem
                                      key={notification.id}
                                      notification={notification}
                                      markAsRead={markAsRead}
                                    />
                                  ))
                                ) : (
                                  <li className="employee-notification-grid">
                                    <div className="employee-notification-content">
                                      <h6>No unread notifications!</h6>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane" id="read_notification_tab">
                            <div
                              className="employee-noti-content"
                              style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                              <ul className="employee-notification-list">
                                {readNotifications.length > 0 ? (
                                  readNotifications.map((notification) => (
                                    <NotificationItem
                                      key={notification.id}
                                      notification={notification}
                                      markAsRead={() => {}}
                                      isRead={true}
                                    />
                                  ))
                                ) : (
                                  <li className="employee-notification-grid">
                                    <div className="employee-notification-content">
                                      <h6>No read notifications!</h6>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NotificationItem = ({ notification, markAsRead, isRead = false }) => {
  const getLinkTo = (type) => {
    switch (type) {
      case "gacl_task":
        return "/gacl-task-assigned";
      default:
        return "/gacl-task-assigned";
    }
  };

  return (
    <li
      className="employee-notification-grid"
      style={{
        backgroundColor: isRead ? "#e0f7fa" : "#fff3e0",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
      }}
      onClick={() => !isRead && markAsRead(notification.id)}
    >
      <div className="employee-notification-icon">
        <Link to={getLinkTo(notification.type)}>
          <span className="badge-soft-danger rounded-circle">HR</span>
        </Link>
      </div>
      <div className="employee-notification-content">
        <Link to={getLinkTo(notification.type)}>
          <h6
            style={{
              color: isRead ? "#6c757d" : "#FF902F",
            }}
          >
            {notification.title}
          </h6>
          <div
            style={{
              fontSize: 11,
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              color: "black",
            }}
          >
            {notification.description}
          </div>
        </Link>
        <ul className="nav">
          <li>{new Date(notification.time).toLocaleString()}</li>
        </ul>
      </div>
    </li>
  );
};

export default GACLDashboard;
