import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

const EmployeeResignationLetter = () => {
  const [signature, setSignature] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSignature(reader.result); // Set the uploaded file as a base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="p-3">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Form>
            <Form.Group className="mb-3" controlId="resignationLetter">
              <Form.Label>Resignation Letter</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="signature">
              <Form.Label>Signature</Form.Label>
              <Row>
                <Col xs={9}>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Col>
              </Row>
              <div
                style={{
                  marginTop: "10px",
                  width: "50%",
                  height: "150px",
                  border: "1px solid #ccc",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {signature ? (
                  <img
                    src={signature}
                    alt="Uploaded Signature"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : (
                  <p>No Signature Uploaded</p>
                )}
              </div>
            </Form.Group>

            <Button variant="warning" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EmployeeResignationLetter;
