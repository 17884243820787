import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Select,
  Form as AntForm,
  Input,
  DatePicker,
  Card as AntCard,
  Tabs,
} from "antd";
import { Button, Modal, Form, Card } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  gaclBoardMeeting,
  companyDetailsList,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../layout/Header";
import GaclSidebar from "../../../layout/GaclSidebar";

const { Item: FormItem } = AntForm;
const { TextArea } = Input;
const { TabPane } = Tabs;

const BoardMeetingList = () => {
  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isGacl, setIsGacl] = useState(false);

  // Form states
  const [formData, setFormData] = useState({
    company: "",
    date: null,
    marketing_review: "",
    sales_review: "",
    automation_review: "",
    profit_loss_account: "",
    balance_sheet: "",
    auditor_report: "",
    auditor_details: "",
    next_quarter_budget: "",
    renewal_tenancy_contract: "",
    permission_chairman: "",
    receivables_payables_review: "",
    esr_compliance: "",
    esr_outsource: "",
    dmcc_compliance: "",
    dmcc_renew: "",
    aml_compliance: "",
    aml_training: "",
    quarter_board: false,
    is_rs_signed: false,
    is_approved: false,
  });

  // Fetch companies list
  const fetchCompanies = useCallback(async () => {
    try {
      const response = await axiosInstance.get(companyDetailsList);
      if (response.data && response.data.results) {
        setCompanies(response.data.results);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
      toast.error("Error fetching companies list");
    }
  }, []);

  // Fetch board meetings
  const fetchBoardMeetings = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(gaclBoardMeeting);
      if (response.data) {
        setDataSource(response.data.results || []);
      }
    } catch (error) {
      console.error("Error fetching board meetings:", error);
      toast.error("Error fetching board meetings");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCompanies();
    fetchBoardMeetings();
  }, [fetchCompanies, fetchBoardMeetings]);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      console.log(response.data.employment_details[0].department_detail);
      setIsGacl(
        response.data.employment_details[0].department_detail.name === "GACL"
      );
    });
  }, []);

  const handleClose = () => {
    setShow(false);
    setSelectedRecord(null);
    setIsUpdating(false);
    setFormData({
      company: "",
      date: null,
      marketing_review: "",
      sales_review: "",
      automation_review: "",
      profit_loss_account: "",
      balance_sheet: "",
      auditor_report: "",
      auditor_details: "",
      next_quarter_budget: "",
      renewal_tenancy_contract: "",
      permission_chairman: "",
      receivables_payables_review: "",
      esr_compliance: "",
      esr_outsource: "",
      dmcc_compliance: "",
      dmcc_renew: "",
      aml_compliance: "",
      aml_training: "",
      quarter_board: false,
      is_rs_signed: false,
      is_approved: false,
    });
  };

  const handleSave = async () => {
    if (!formData.company || !formData.date) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        ...formData,
        date: moment(formData.date).format("YYYY-MM-DD"),
      };

      let response;
      if (isUpdating) {
        delete payload.id;
        response = await axiosInstance.post(gaclBoardMeeting, payload);
        toast.success("New board meeting created from existing record!");
      } else if (selectedRecord) {
        response = await axiosInstance.patch(
          `${gaclBoardMeeting}${selectedRecord.id}/`,
          payload
        );
        toast.success("Board meeting updated successfully!");
      } else {
        response = await axiosInstance.post(gaclBoardMeeting, payload);
        toast.success("Board meeting created successfully!");
      }

      await fetchBoardMeetings();
      handleClose();
    } catch (error) {
      console.error("Error saving board meeting:", error);
      toast.error(
        error.response?.data?.message ||
          "Error saving board meeting. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Company",
      dataIndex: ["company_details", "name"],
      key: "company",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Quarter Board",
      dataIndex: "quarter_board",
      key: "quarter_board",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) =>
        record.is_approved
          ? "Approved"
          : record.is_rs_signed
          ? "Signed"
          : "Pending",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setSelectedRecord(record);
              setIsUpdating(false);
              setFormData({
                ...record,
                date: moment(record.date),
              });
              setShow(true);
            }}
          >
            Edit
          </Button>
          <Button
            variant="success"
            size="sm"
            onClick={() => {
              setSelectedRecord(record);
              setIsUpdating(true);
              setFormData({
                ...record,
                date: moment(record.date),
              });
              setShow(true);
            }}
          >
            Update & Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {isGacl ? (
        <>
          <Header />
          <GaclSidebar />
        </>
      ) : (
        <></>
      )}
      <div className="page-wrapper">
        <div className="page-header mb-4 mt-4">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Board Meeting Management</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">GACL</li>
                <li className="breadcrumb-item active">Board Meetings</li>
              </ul>
            </div>
            <div className="col-auto float-end ms-auto">
              <Button
                variant="success"
                onClick={() => {
                  setShow(true);
                  setSelectedRecord(null);
                }}
              >
                <i className="fa fa-plus me-1"></i> Add Board Meeting
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <AntCard>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  loading={isLoading}
                  rowKey="id"
                />
              </div>
            </AntCard>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isUpdating
                ? "Create New from Existing Board Meeting"
                : selectedRecord
                ? "Edit Board Meeting"
                : "Add Board Meeting"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Tabs defaultActiveKey="meeting-details">
                <TabPane tab="Meeting Details" key="meeting-details">
                  <div className="row">
                    <h4 className="form-section-title pb-2">
                      Basic Information
                    </h4>
                    <div className="col-md-6">
                      <FormItem label="Company" required>
                        <Select
                          value={formData.company}
                          onChange={(value) =>
                            setFormData({ ...formData, company: value })
                          }
                          style={{ width: "100%" }}
                        >
                          {companies.map((company) => (
                            <Select.Option key={company.id} value={company.id}>
                              {company.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                    <div className="col-md-6">
                      <FormItem label="Date" required>
                        <DatePicker
                          value={formData.date}
                          onChange={(date) =>
                            setFormData({ ...formData, date })
                          }
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </FormItem>
                    </div>
                  </div>
                </TabPane>

                <TabPane tab="Operations" key="operations">
                  <div className="row">
                    <h4 className="form-section-title bp-2">
                      REVIEW OF OPERATIONS
                    </h4>
                    <div className="col-md-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Review of Marketing">
                            <TextArea
                              value={formData.marketing_review}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  marketing_review: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Review of Sales">
                            <TextArea
                              value={formData.sales_review}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  sales_review: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Review of Automation">
                            <TextArea
                              value={formData.automation_review}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  automation_review: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </TabPane>

                <TabPane tab="Accounts" key="accounts">
                  <div className="row">
                    <h4 className="form-section-title pb-2">
                      REVIEW OF ACCOUNTS
                    </h4>
                    <div className="col-md-6">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Profit and Loss Account">
                            <TextArea
                              value={formData.profit_loss_account}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  profit_loss_account: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Balance Sheet">
                            <TextArea
                              value={formData.balance_sheet}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  balance_sheet: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Appointment of Auditors">
                            <TextArea
                              value={formData.auditor_details}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  auditor_details: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Review and acceptance of Audit report">
                            <TextArea
                              value={formData.auditor_report}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  auditor_report: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Budget for the next quarter">
                            <TextArea
                              value={formData.next_quarter_budget}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  next_quarter_budget: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </TabPane>

                <TabPane tab="Compliances" key="compliances">
                  <div className="row">
                    <h4 className="form-section-title pb-2">
                      REVIEW OF COMPLIANCES
                    </h4>

                    {/* ESR Compliance Section */}
                    <h5 className="pb-2">A. Compliance with ESR regulations</h5>
                    <div className="col-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <label className="compliance-label">
                                The company that is carrying on a Relevant
                                activity, after the last day of the end of each
                                Financial Year of the Licensee, prepare and
                                submit to the Regulatory Authority a report
                              </label>
                            </div>
                            <div className="col-md-6">
                              <TextArea
                                value={formData.esr_compliance}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    esr_compliance: e.target.value,
                                  })
                                }
                                rows={4}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <label className="compliance-label">
                                If the company has outsourced a Relevant
                                Activity they must demonstrate the following by
                                a report with information about - the level of
                                resources employed by the third party service
                                provider, demonstrating that their activities,
                                employees, operating expenditures and premises
                                are adequate in relation to the level of
                                Relevant Activity outsourced.
                              </label>
                            </div>
                            <div className="col-md-6">
                              <TextArea
                                value={formData.esr_outsource}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    esr_outsource: e.target.value,
                                  })
                                }
                                rows={4}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    {/* DMCC Compliance Section */}
                    <h5 className="pb-2">
                      B. Compliance with DMCC regulations
                    </h5>
                    <div className="col-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <label className="compliance-label">
                                No DMCC Entity may carry out any business for
                                which it is not licensed or registered
                              </label>
                            </div>
                            <div className="col-md-6">
                              <TextArea
                                value={formData.dmcc_compliance}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    dmcc_compliance: e.target.value,
                                  })
                                }
                                rows={4}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <label className="compliance-label">
                                If a DMCC Entity fails to renew its Licence on
                                or before the date of expiry, the Registrar may
                                grant, in its discretion, a limited grace period
                                in which such DMCC Entity is required to
                                complete the renewal process. If such DMCC
                                Entity fails to renew its Licence within any
                                permitted grace period, then its Licence may be
                                suspended or terminated in accordance with Rule
                                5 and the Registrar may take any such action as
                                it deems necessary
                              </label>
                            </div>
                            <div className="col-md-6">
                              <TextArea
                                value={formData.dmcc_renew}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    dmcc_renew: e.target.value,
                                  })
                                }
                                rows={4}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    {/* AML Compliance Section */}
                    <h5 className="pb-2">C. Compliance with AML regulations</h5>
                    <div className="col-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <label className="compliance-label">
                                Pursuant to Article (44) 1 of 'Cabinet Decision
                                No. (10) of 2019 Concerning the Implementing
                                Regulations of Decree Law No. (20) of 2018 on
                                Anti-Money Laundering and Combating the
                                Financing of Terrorism and Illegal
                                Organizations', DNFBPS in UAE are required to
                                file annual AML/CFT Report.
                              </label>
                            </div>
                            <div className="col-md-6">
                              <TextArea
                                value={formData.aml_compliance}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    aml_compliance: e.target.value,
                                  })
                                }
                                rows={4}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <label className="compliance-label">
                                Prepare, execute and document training and
                                development programs and plans for the
                                institution’s employees on Money Laundering and
                                the Financing of Terrorism and Financing of
                                Illegal Organisations, and the means to combat
                                them
                              </label>
                            </div>
                            <div className="col-md-6">
                              <TextArea
                                value={formData.aml_training}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    aml_training: e.target.value,
                                  })
                                }
                                rows={4}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  {/* Add the following styles */}
                  <style jsx>{`
                    .compliance-label {
                      font-size: 0.9rem;
                      color: #666;
                      margin-bottom: 0;
                      padding-right: 1rem;
                    }
                    .align-items-center {
                      align-items: center;
                    }
                  `}</style>
                </TabPane>

                <TabPane tab="Agenda" key="agenda">
                  <div className="row">
                    <h4 className="form-section-title pb-2">
                      Additional Information
                    </h4>

                    <div className="col-md-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Renewal of Tenancy Contract">
                            <TextArea
                              value={formData.renewal_tenancy_contract}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  renewal_tenancy_contract: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-md-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Any other item by the permission of the Chair">
                            <TextArea
                              value={formData.permission_chairman}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  permission_chairman: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card className="mb-3">
                        <Card.Body>
                          <FormItem label="Review of Recievables and Payables">
                            <TextArea
                              value={formData.receivables_payables_review}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  receivables_payables_review: e.target.value,
                                })
                              }
                              rows={4}
                            />
                          </FormItem>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-12">
                      <Card>
                        <Card.Body>
                          <div className="row">
                            <div className="col-md-4">
                              <FormItem label="Quarter Board">
                                <Form.Check
                                  type="checkbox"
                                  checked={formData.quarter_board}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      quarter_board: e.target.checked,
                                    })
                                  }
                                />
                              </FormItem>
                            </div>
                            <div className="col-md-4">
                              <FormItem label="RS Signed">
                                <Form.Check
                                  type="checkbox"
                                  checked={formData.is_rs_signed}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      is_rs_signed: e.target.checked,
                                    })
                                  }
                                />
                              </FormItem>
                            </div>
                            <div className="col-md-4">
                              <FormItem label="Approved">
                                <Form.Check
                                  type="checkbox"
                                  checked={formData.is_approved}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      is_approved: e.target.checked,
                                    })
                                  }
                                />
                              </FormItem>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={isLoading}>
              {isLoading ? "Saving..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <style jsx>{`
        .ant-tabs-nav {
          margin-bottom: 24px;
        }
        .ant-tabs-tab {
          padding: 12px 24px;
        }
        .ant-tabs-tab.ant-tabs-tab-active {
          background-color: #f0f2f5;
        }
      `}</style>
    </>
  );
};

export default BoardMeetingList;
