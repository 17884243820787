export const RSsidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Employee Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "Documents",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-text",
        subMenus: [
          {
            menuValue: "Add Document",

            route: "/file-upload",
          },
          {
            menuValue: "View Documents",

            route: "/view-documents",
          },
          {
            menuValue: "Document Naming",

            route: "/document-naming",
          },
        ],
      },
      {
        menuValue: "ZII",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-list",
        subMenus: [
          {
            menuValue: "Add ZII",
            route: "/add-zii",
          },
          {
            menuValue: "Your ZII",
            route: "/employee-zii",
          },
        ],
      },

      //board meeting
      {
        menuValue: "Board Meeting",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/board-meeting",
        icon: "la la-list-alt",
      },
      {
        menuValue: "Approvals",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-check-square",
        subMenus: [
          {
            menuValue: "Training Course Approval",
            route: "/training-approval",
          },
          {
            menuValue: "Policy Approval",
            route: "/policy-approval",
          },
        ],
      },
      {
        menuValue: "Courses",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Your Courses",
            route: "/employee-courses",
          },
        ],
      },
      {
        menuValue: "Policies",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-pdf-o",
        subMenus: [
          {
            menuValue: "View Policies",
            route: "/employee-policy",
          },
        ],
      },
      {
        menuValue: "Appraisal",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-star-o",
        subMenus: [
          {
            menuValue: "Weekly Appraisal",
            route: "/employee-appraisal",
          },
          {
            menuValue: "My Appraisal",
            route: "/appraisal-table",
          },
          {
            menuValue: "Department Appraisal Report",
            route: "/appraisal-report-alldept",
          },
        ],
      },
      {
        menuValue: "Holidays",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/holidays-calendar",
        icon: "la la-calendar-minus-o",
      },
      {
        menuValue: "Attendance",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-attendance",
        icon: "la la-calendar-check-o",
      },
      {
        menuValue: "Leaves",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar",
        subMenus: [
          {
            menuValue: "Leave Form",
            route: "/leave-form-rs",
          },
          {
            menuValue: "My Leaves",
            route: "/your-leaves",
          },
          {
            menuValue: "Approve Leaves",
            route: "/approve-leaves",
          },
        ],
      },
      {
        menuValue: "QPA",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder",
        subMenus: [
          {
            menuValue: "Nominate for QPA",
            route: "/nomination-details",
          },
          {
            menuValue: "Nominated QPA",
            route: "/nominated-qpa",
          },
          {
            menuValue: "Your QPA",
            route: "/your-qpa",
          },
          {
            menuValue: "QPA Report",
            route: "/qpa-report",
          },
        ],
      },
      {
        menuValue: "Resignation",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-external-link-square",
        subMenus: [
          {
            menuValue: "View Resignation letter ",
            route: "/view-resignation-letter-rs",
          },
        ],
      },
    ],
  },
];
