import React, { useState } from "react";
import { Table, Button, Modal, Form, Row, Col } from "react-bootstrap";

const ViewResignationLetterTL = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <div>
      <div className="p-3">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Employee Name</th>
                  {/* <th>Department</th> */}
                  <th>Resignation Date</th>
                  <th>View</th>
                  <th>Approval Status HR</th>
                  <th>Approval Status RS Sir</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  {/* <td>IT</td>    */}
                  <td>10-10-24</td>
                  <td>
                    <Button variant="dark" onClick={handleShow}>
                      View
                    </Button>
                  </td>
                  <td>Pending</td>
                  <td>Pending</td>
                </tr>
              </tbody>
            </Table>

            {/* Modal for Resignation Letter Form */}
            {/* Modal for Resignation Letter Form */}
            <Modal show={showModal} onHide={handleClose} centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title> View Resignation Letter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  {/* <Row className="mb-3">
                    <Col>
                      <Form.Group controlId="dateOfResignation">
                        <Form.Label>Date of Resignation</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter date of resignation"
                          defaultValue="10-10-24"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="lastWorkingDate">
                        <Form.Label>Last Working Date</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last working date"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="supervisorName">
                        <Form.Label>Supervisor/TL Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter supervisor name"
                          defaultValue="Tanmay"
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <Form.Group controlId="resignationLetter" className="mb-3">
                    <Form.Label>Resignation Letter</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Write your resignation letter here..."
                    />
                  </Form.Group>
                  <Row>
                    <Col>
                      {" "}
                      <Form.Group controlId="successorName" className="mb-3">
                        <Form.Label>Successor Name</Form.Label>
                        <Form.Select>
                          {[
                            "John Doe",
                            "Jane Smith",
                            "Michael Brown",
                            "Sarah Johnson",
                          ].map((name, index) => (
                            <option key={index} value={name}>
                              {name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="signature" className="mb-3">
                        <Form.Label>Signature</Form.Label>
                        <Form.Control type="file" accept="image/*" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-start">
                <Button
                  variant="warning"
                  className="px-4"
                  onClick={handleClose}
                >
                  Send
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default ViewResignationLetterTL;
