import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Modal,
  Form,
  Upload,
  Input,
  Button as AntButton,
  Select,
  Tabs,
} from "antd";
import { Button } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addTaskGacl,
  employeeDetails,
  assignedTaskGacl,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../layout/Header";
import GaclSideBar from "../../../layout/GaclSidebar";

const taskTypeOptions = [
  { value: "all", label: "All Types" },
  { value: "ESR", label: "ESR" },
  { value: "AML", label: "AML" },
  { value: "Internal Audit", label: "Internal Audit" },
  { value: "External Audit", label: "External Audit" },
  { value: "Personal Income Tax", label: "Personal Income Tax" },
  { value: "Remittance", label: "Remittance" },
];

const TaskAssigned = () => {
  const [employeeID, setEmployeeID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [submissionFile, setSubmissionFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionKey, setSubmissionKey] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [taskDetails, setTaskDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [taskTypeFilter, setTaskTypeFilter] = useState("all");

  // Fetch employee details for current user
  const fetchEmployeeDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeID(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Error fetching employee details");
    }
  }, []);

  // Fetch assigned tasks
  const fetchAssignedTasks = useCallback(
    async (page = 1) => {
      if (!employeeID) return;

      setIsLoading(true);
      try {
        let url = `${addTaskGacl}?assignee=${employeeID}&page=${page}&page_size=${pageSize}`;

        // Add status filter if selected
        if (statusFilter === "accepted") {
          url += "&is_accepted=true&is_done=false";
        } else if (statusFilter === "done") {
          url += "&is_done=true";
        }

        // Add task type filter
        if (taskTypeFilter !== "all") {
          url += `&task=${taskTypeFilter}`;
        }

        const response = await axiosInstance.get(url);
        if (response.data) {
          setDataSource(response.data.results || []);
          setTotalCount(response.data.count || 0);
        }
      } catch (error) {
        console.error("Error fetching assigned tasks:", error);
        toast.error("Error fetching assigned tasks");
        setDataSource([]);
        setTotalCount(0);
      } finally {
        setIsLoading(false);
      }
    },
    [employeeID, statusFilter, taskTypeFilter]
  );

  // Handle task acceptance
  const handleAcceptTask = async (taskId) => {
    try {
      await axiosInstance.patch(`${addTaskGacl}${taskId}/`, {
        is_accepted: true,
        accepted_time: moment().format(),
      });
      toast.success("Task accepted successfully!");
      fetchAssignedTasks(currentPage); // Refresh the current page
    } catch (error) {
      console.error("Error accepting task:", error);
      toast.error("Error accepting task. Please try again.");
    }
  };

  // Add pagination change handler
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
    fetchAssignedTasks(page);
  };

  // Add function to check if submission exists
  const checkSubmissionExists = async (taskId) => {
    try {
      const response = await axiosInstance.get(
        `${assignedTaskGacl}?task=${taskId}`
      );
      return response.data.results.length > 0 ? response.data.results[0] : null;
    } catch (error) {
      console.error("Error checking submission:", error);
      return null;
    }
  };

  // Update handleSubmitTask function
  const handleSubmitTask = async () => {
    if (!submissionFile || !submissionKey) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsSubmitting(true);
    try {
      // First update the task status
      await axiosInstance.patch(`${addTaskGacl}${selectedTask.id}/`, {
        is_done: true,
        finished_time: moment().format(),
      });

      // Check if submission already exists
      const existingSubmission = await checkSubmissionExists(selectedTask.id);

      // Prepare form data
      const formData = new FormData();
      formData.append("comments", submissionKey);
      formData.append("assignee", employeeID);
      formData.append("task", selectedTask.id);
      formData.append("file", submissionFile);
      formData.append("status", "Pending"); // Add status as Pending

      if (existingSubmission) {
        // If submission exists, use PATCH
        await axiosInstance.patch(
          `${assignedTaskGacl}${existingSubmission.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        // If no submission exists, use POST
        await axiosInstance.post(assignedTaskGacl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      toast.success("Task submitted successfully!");
      handleCloseSubmitModal();
      fetchAssignedTasks(currentPage);
    } catch (error) {
      console.error("Error submitting task:", error);
      toast.error(
        error.response?.data?.message ||
          "Error submitting task. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update modal close handler
  const handleCloseSubmitModal = () => {
    setShowSubmitModal(false);
    setSelectedTask(null);
    setSubmissionFile(null);
    setSubmissionKey("");
    // Reset file input
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  // Add function to fetch task details
  const fetchTaskDetails = async (taskId) => {
    setIsLoadingDetails(true);
    try {
      const response = await axiosInstance.get(
        `${assignedTaskGacl}?task=${taskId}`
      );
      if (response.data && response.data.results.length > 0) {
        setTaskDetails(response.data.results[0]);
        setShowDetailsModal(true);
      } else {
        toast.error("No details found for this task");
      }
    } catch (error) {
      console.error("Error fetching task details:", error);
      toast.error("Error fetching task details");
    } finally {
      setIsLoadingDetails(false);
    }
  };

  // Add handleStatusChange function
  const handleStatusChange = (value) => {
    setStatusFilter(value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleTaskTypeFilterChange = (value) => {
    setTaskTypeFilter(value);
    setCurrentPage(1);
    setDataSource([]);
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, [fetchEmployeeDetails]);

  useEffect(() => {
    if (employeeID) {
      fetchAssignedTasks(1);
    }
  }, [employeeID, fetchAssignedTasks]);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
      // order by id in descending order
      sorter: (a, b) => b.id - a.id,
    },
    {
      title: "Task Type",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Assigned By",
      dataIndex: "user_details",
      key: "assigned_by",
      render: (user_details) => user_details?.employee_name || "-",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (file) =>
        file ? (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => window.open(file, "_blank")}
          >
            <i className="fas fa-file-download me-2"></i>
            View File
          </Button>
        ) : (
          "-"
        ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url) =>
        url ? (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => window.open(url, "_blank")}
          >
            <i className="fas fa-external-link-alt me-2"></i>
            View URL
          </Button>
        ) : (
          "-"
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        // Not accepted - show only Accept button
        if (!record.is_accepted) {
          return (
            <Button
              variant="success"
              size="sm"
              onClick={() => handleAcceptTask(record.id)}
            >
              Accept
            </Button>
          );
        }

        // Accepted but not completed - show only Submit button
        if (record.is_accepted && !record.is_done) {
          return (
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setSelectedTask(record);
                setShowSubmitModal(true);
              }}
            >
              Submit
            </Button>
          );
        }

        // Completed - show only Show Details button
        return (
          <Button
            variant="info"
            size="sm"
            onClick={() => fetchTaskDetails(record.id)}
          >
            Show Details
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Header />
      <GaclSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4 align-items-center">
            <div className="col-auto">
              <h1 className="text-2xl font-bold" style={{ margin: 0 }}>
                Your Tasks
              </h1>
            </div>
            <div className="col-auto">
              <Form.Item label="Task Type" className="mb-0">
                <Select
                  value={taskTypeFilter}
                  onChange={handleTaskTypeFilterChange}
                  style={{ width: 200 }}
                  options={taskTypeOptions}
                  dropdownMatchSelectWidth={false}
                />
              </Form.Item>
            </div>
            <div className="col d-flex justify-content-end">
              <Tabs
                activeKey={statusFilter || "all"}
                onChange={(key) =>
                  handleStatusChange(key === "all" ? null : key)
                }
                items={[
                  { key: "all", label: "All Tasks" },
                  { key: "accepted", label: "Accepted" },
                  { key: "done", label: "Completed" },
                ]}
                className="task-management-tabs"
                size="middle"
                tabBarGutter={24}
              />
            </div>
          </div>

          <div className="table-responsive">
            <Table
              className="table-striped"
              columns={columns}
              dataSource={dataSource}
              rowKey={(record) => record.id}
              loading={isLoading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                onChange: handlePaginationChange,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
            />
          </div>
        </div>
      </div>

      {/* Update Modal to use Ant Design's Modal */}
      <Modal
        open={showSubmitModal}
        onCancel={handleCloseSubmitModal}
        title="Submit Task"
        footer={[
          <AntButton key="back" onClick={handleCloseSubmitModal}>
            Cancel
          </AntButton>,
          <AntButton
            key="submit"
            type="primary"
            onClick={handleSubmitTask}
            loading={isSubmitting}
            disabled={isSubmitting || !submissionFile || !submissionKey}
          >
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" />
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </AntButton>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label="Comments"
            required
            tooltip="Please enter your comments"
          >
            <Input.TextArea
              placeholder="Enter your comments"
              value={submissionKey}
              onChange={(e) => setSubmissionKey(e.target.value)}
              disabled={isSubmitting}
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label="Upload Submission File"
            required
            extra="Maximum file size: 5MB"
          >
            <input
              type="file"
              className="form-control"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && file.size > 5 * 1024 * 1024) {
                  toast.warning("File size should not exceed 5MB");
                  e.target.value = null;
                  return;
                }
                setSubmissionFile(file);
              }}
              disabled={isSubmitting}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Details Modal */}
      <Modal
        open={showDetailsModal}
        onCancel={() => setShowDetailsModal(false)}
        title="Task Details"
        width={800}
        footer={[
          <AntButton key="close" onClick={() => setShowDetailsModal(false)}>
            Close
          </AntButton>,
        ]}
      >
        {isLoadingDetails ? (
          <div className="text-center">Loading details...</div>
        ) : taskDetails ? (
          <Form layout="vertical">
            <div className="row">
              <div className="col-md-6">
                <Form.Item label="Task Type">
                  <Input value={taskDetails.task_details?.task} disabled />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Status">
                  <Input value={taskDetails.status} disabled />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Assigned By">
                  <Input
                    value={
                      taskDetails.task_details?.user_details?.employee_name
                    }
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Due Date">
                  <Input
                    value={moment(taskDetails.task_details?.due_date).format(
                      "DD-MM-YYYY"
                    )}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Accepted Time">
                  <Input
                    value={
                      taskDetails.task_details?.accepted_time
                        ? moment(taskDetails.task_details.accepted_time).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        : "-"
                    }
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Finished Time">
                  <Input
                    value={
                      taskDetails.task_details?.finished_time
                        ? moment(taskDetails.task_details.finished_time).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        : "-"
                    }
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item label="Task File">
                  {taskDetails.task_details?.file ? (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() =>
                        window.open(taskDetails.task_details.file, "_blank")
                      }
                    >
                      <i className="fas fa-file-download me-2"></i>
                      View Task File
                    </Button>
                  ) : (
                    "-"
                  )}
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item label="Submission File">
                  {taskDetails.file ? (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => window.open(taskDetails.file, "_blank")}
                    >
                      <i className="fas fa-file-download me-2"></i>
                      View Submission File
                    </Button>
                  ) : (
                    "-"
                  )}
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item label="Comments">
                  <Input.TextArea
                    value={taskDetails.comments || "-"}
                    disabled
                    rows={4}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

export default TaskAssigned;
