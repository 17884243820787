import React, { useState, useEffect } from "react";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  ziiReport,
  getZii,
} from "../../../../../helpers/endpoints/api_endpoints";
import { Table } from "antd";
import { Button } from "react-bootstrap";
import EmployeeZIIList from "../EmployeeZIIList/EmployeeZIIList";

const ZIIReport = () => {
  const currentYear = new Date().getFullYear();
  const [activeMonthButton, setActiveMonthButton] = useState(
    new Date().getUTCMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [userID, setUserID] = useState(null);
  const [activeWeekButton, setActiveWeekButton] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchReportData(selectedYear, activeMonthButton);
  }, [selectedYear, activeMonthButton]);

  const fetchReportData = async (year, month) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(ziiReport(year, month));
      const sortedData = response.data.sort(
        (a, b) => b.average_score - a.average_score
      );
      setReportData(sortedData);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
    setIsLoading(false);
  };

  const handleWeekClick = (weekNumber) => {
    setActiveWeekButton(weekNumber);
    console.log(`Week-${weekNumber} button clicked`);
  };

  const handleMonthClick = (monthNumber) => {
    setActiveMonthButton(monthNumber);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const generateYearOptions = () => {
    const years = [];
    for (let i = currentYear; i >= 2024; i--) {
      years.push(i);
    }
    return years;
  };

  const handleShowDetails = (record) => {
    setSelectedRecord(record);
    setUserID(record.employee_id); // Correctly set the userID from the selected record
    console.log("Selected UserID:", record.employee_id);
    setShowDetails(true);
  };

  // Add this useEffect hook to handle side effects when `showDetails` changes
  useEffect(() => {
    if (showDetails && selectedRecord) {
      const userid = userID;
      const monthName = new Date(Date.UTC(2020, activeMonthButton - 1))
        .toLocaleString("default", { month: "long" })
        .toLowerCase();
      const weekNumber = activeWeekButton;

      axiosInstance
        .get(getZii(userid, monthName, weekNumber, selectedYear))
        .then((response) => {
          const sortedData = response.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setData(sortedData);
        })
        .catch((err) => console.log(err));
    }
  }, [
    showDetails,
    selectedRecord,
    activeMonthButton,
    activeWeekButton,
    userID,
  ]);

  const handleBack = () => {
    setShowDetails(false);
    setSelectedRecord(null);
    setActiveWeekButton(1);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Number of ZII Written",
      dataIndex: "num_zii_written",
      key: "num_zii_written",
    },
    {
      title: "Total Score",
      dataIndex: "total_score",
      key: "total_score",
      sorter: (a, b) => a.total_score - b.total_score,
      defaultSortOrder: "descend",
    },
    {
      title: "Average Score",
      dataIndex: "average_score",
      key: "average_score",
      sorter: (a, b) => a.average_score - b.average_score,
      defaultSortOrder: "descend",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          onClick={() => handleShowDetails(record)}
          className="btn btn-primary me-2"
        >
          Show Details
        </Button>
      ),
    },
  ];

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          {!showDetails ? (
            <>
              <div className="row mb-2 align-items-center">
                <div className="col-6">
                  <h1 className="text-2xl font-bold">Monthly ZII Reports</h1>
                </div>
                <div className="col-6 text-end">
                  <select
                    className="form-select"
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={{ maxWidth: "150px", display: "inline-block" }}
                  >
                    {generateYearOptions().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="tabs mb-1">
                {Array.from({ length: 12 }, (_, i) => i + 1).map(
                  (monthNumber) => (
                    <div
                      key={monthNumber}
                      className={`tab-item ${
                        activeMonthButton === monthNumber ? "active" : ""
                      }`}
                      onClick={() => handleMonthClick(monthNumber)}
                    >
                      {new Date(Date.UTC(2020, monthNumber - 1)).toLocaleString(
                        "default",
                        { month: "long" }
                      )}
                    </div>
                  )
                )}
              </div>
              <div className="report-table mt-4">
                <Table
                  columns={columns}
                  dataSource={reportData}
                  rowKey="employee_id"
                  loading={isLoading}
                  pagination={{ pageSize: 10 }}
                  className="table-striped"
                  scroll={{ x: "100%" }}
                />
              </div>
            </>
          ) : (
            <div className="details-view">
              <Button onClick={handleBack} className="btn btn-secondary mb-1">
                Back
              </Button>
              {selectedRecord && (
                <>
                  <div className="btn mb-1">
                    {[1, 2, 3, 4, 5].map((weekNumber) => (
                      <button
                        key={weekNumber}
                        type="button"
                        className={`btn mx-1 ${
                          activeWeekButton === weekNumber
                            ? "btn-success"
                            : "btn-outline-success"
                        }`}
                        onClick={() => handleWeekClick(weekNumber)}
                      >
                        Week-{weekNumber}
                      </button>
                    ))}
                  </div>
                  <EmployeeZIIList
                    filteredData={data}
                    hideButtons={true}
                    selectedYear={selectedYear} // Pass the selected year
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .tabs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          border-bottom: 2px solid #ddd;
          overflow-x: auto;
        }
        .tab-item {
          cursor: pointer;
          padding: 10px 20px;
          color: gray;
          white-space: nowrap;
        }
        .tab-item.active {
          color: black;
          font-weight: bold;
          border-bottom: 2px solid black;
        }
        .details-view {
          padding: 20px;
        }
        @media (max-width: 768px) {
          .tabs {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
          }
          .tab-item {
            text-align: center;
          }
        }
      `}</style>
    </>
  );
};

export default ZIIReport;
