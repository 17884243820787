import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Select, Spin, Tabs, Tag, Tooltip } from "antd";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeList,
  employeeDetails,
  departmentList,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import { FaListAlt, FaExternalLinkAlt } from "react-icons/fa";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const HRApproveLeaves = () => {
  const [leaves, setLeaves] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [activeTab, setActiveTab] = useState("All");

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axiosInstance.get(departmentList);
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
        toast.error("Failed to fetch departments");
      }
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    axiosInstance.get(employeeDetails).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    );
  }, []);

  const fetchLeaves = useCallback(async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page: 1,
        page_size: 10,
      });

      if (statusFilter) {
        params.append("status", statusFilter);
      }

      if (activeTab !== "All") {
        params.append("department", activeTab);
      }

      const response = await axiosInstance.get(
        `${addLeaves}?${params.toString()}`
      );
      setLeaves(response.data.results);
    } catch (error) {
      console.error("Error fetching leaves:", error);
      toast.error("Failed to fetch leaves");
    } finally {
      setLoading(false);
    }
  }, [statusFilter, activeTab]);

  useEffect(() => {
    fetchLeaves();
  }, [fetchLeaves]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
      render: (_, record) => record?.user_details?.employee_name || "-",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text) =>
        text ? (text.length > 20 ? text.slice(0, 20) + "..." : text) : "-",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "Total Days",
      dataIndex: "total_days",
      key: "total_days",
      render: (days) => days || "-",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let color = "warning";
        if (record.status === "Approved") color = "success";
        else if (record.status === "Rejected") color = "error";
        return <Tag color={color}>{record.status || "Pending"}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="d-flex gap-2">
          <Button type="primary" onClick={() => handleViewLeave(record)}>
            View
          </Button>
          {record?.tasks?.length > 0 && (
            <Tooltip title={`${record.tasks.length} Task(s) Available`}>
              <Button
                type="default"
                icon={<FaListAlt />}
                onClick={() => handleViewTasks(record.tasks)}
              />
            </Tooltip>
          )}
          {record?.document && (
            <Tooltip title="View Document">
              <Button
                type="default"
                icon={<FaExternalLinkAlt />}
                onClick={() => window.open(record.document, "_blank")}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleViewLeave = (leave) => {
    setSelectedLeave(leave);
    setIsModalOpen(true);
  };

  const handleViewTasks = (tasks) => {
    setSelectedTasks(tasks);
    setShowTaskModal(true);
  };

  const handleApproveLeave = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${addLeaves}${selectedLeave.id}/`, {
        status: "Approved",
        approved_by: employeeID,
        approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      toast.success("Leave approved successfully");
      fetchLeaves();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error approving leave:", error);
      toast.error("Failed to approve leave");
    } finally {
      setLoading(false);
    }
  };

  const handleRejectLeave = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${addLeaves}${selectedLeave.id}/`, {
        status: "Rejected",
        approved_by: employeeID,
        approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      toast.success("Leave rejected successfully");
      fetchLeaves();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error rejecting leave:", error);
      toast.error("Failed to reject leave");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header d-flex justify-content-between align-items-center">
              <h5>HR Approve Leaves</h5>
              <Select
                placeholder="Filter by Status"
                allowClear
                style={{ width: 200 }}
                onChange={(value) => setStatusFilter(value)}
                value={statusFilter}
              >
                <Select.Option value="Pending">Pending</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Rejected">Rejected</Select.Option>
              </Select>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Tabs
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    defaultActiveKey="All"
                  >
                    <Tabs.TabPane tab="All Departments" key="All">
                      <Spin spinning={loading}>
                        <Table
                          dataSource={leaves}
                          columns={columns}
                          rowKey="id"
                          pagination={{
                            pageSize: 10,
                            showTotal: (total) => `Total ${total} items`,
                          }}
                          bordered
                          scroll={{ x: 1000 }}
                        />
                      </Spin>
                    </Tabs.TabPane>
                    {departments.map((dept) => (
                      <Tabs.TabPane tab={dept.name} key={dept.id}>
                        <Spin spinning={loading}>
                          <Table
                            dataSource={leaves}
                            columns={columns}
                            rowKey="id"
                            pagination={{
                              pageSize: 10,
                              showTotal: (total) => `Total ${total} items`,
                            }}
                            bordered
                            scroll={{ x: 1000 }}
                          />
                        </Spin>
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={isModalOpen}
            onHide={() => setIsModalOpen(false)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Leave Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedLeave && (
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="employee">
                        <Form.Label>Employee</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={
                            selectedLeave?.user_details?.employee_name || "-"
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <div>
                          <Tag
                            color={
                              selectedLeave.status === "Approved"
                                ? "success"
                                : selectedLeave.status === "Rejected"
                                ? "error"
                                : "warning"
                            }
                          >
                            {selectedLeave.status}
                          </Tag>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Contact Person</Form.Label>
                        <Form.Control
                          type="text"
                          value={
                            selectedLeave?.contact_details?.employee_name || ""
                          }
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Task Assigned To</Form.Label>
                        <Form.Control
                          type="text"
                          value={
                            selectedLeave?.tasks[0]?.assignee_details
                              ?.employee_name || "-"
                          }
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {(selectedLeave.status === "Approved" ||
                    selectedLeave.status === "Rejected") && (
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="approvedBy">
                          <Form.Label>{`${selectedLeave.status} By`}</Form.Label>
                          <Form.Control
                            type="text"
                            readOnly
                            value={
                              selectedLeave?.approver_details?.employee_name ||
                              "-"
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="approvedDate">
                          <Form.Label>{`${selectedLeave.status} Date`}</Form.Label>
                          <Form.Control
                            type="text"
                            readOnly
                            value={
                              selectedLeave?.approved_at
                                ? moment(selectedLeave.approved_at).format(
                                    "DD-MM-YYYY HH:mm"
                                  )
                                : "-"
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="startDate">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={moment(selectedLeave.start_date).format(
                            "DD-MM-YYYY"
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="endDate">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={moment(selectedLeave.end_date).format(
                            "DD-MM-YYYY"
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="reason">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          readOnly
                          value={selectedLeave.reason}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {selectedLeave?.leave_details?.length > 0 && (
                    <div className="mt-4">
                      <h5>Daily Leave Details</h5>
                      <Table
                        dataSource={selectedLeave.leave_details}
                        columns={[
                          {
                            title: "Date",
                            dataIndex: "date",
                            key: "date",
                            render: (date) => moment(date).format("DD-MM-YYYY"),
                          },
                          {
                            title: "Leave Type",
                            dataIndex: "leave_specification",
                            key: "leave_specification",
                          },
                        ]}
                        pagination={false}
                        rowKey="date"
                        size="small"
                        scroll={{ y: 200 }}
                      />
                    </div>
                  )}
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              {selectedLeave?.document && (
                <Button
                  variant="primary"
                  onClick={() => window.open(selectedLeave.document, "_blank")}
                >
                  View Document
                </Button>
              )}
              {selectedLeave?.status === "Pending" && (
                <>
                  <Button variant="danger" onClick={handleRejectLeave}>
                    Reject
                  </Button>
                  <Button variant="success" onClick={handleApproveLeave}>
                    Approve
                  </Button>
                </>
              )}
              <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showTaskModal}
            onHide={() => setShowTaskModal(false)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Task Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table
                dataSource={selectedTasks}
                columns={[
                  {
                    title: "Task Description",
                    dataIndex: "task_description",
                    key: "task_description",
                  },
                  {
                    title: "Platform Link",
                    dataIndex: "platform_link",
                    key: "platform_link",
                    render: (link) =>
                      link ? (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaExternalLinkAlt /> Open Link
                        </a>
                      ) : (
                        "-"
                      ),
                  },
                  {
                    title: "Document Link",
                    dataIndex: "document_link",
                    key: "document_link",
                    render: (link) =>
                      link ? (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaExternalLinkAlt /> Open Document
                        </a>
                      ) : (
                        "-"
                      ),
                  },
                  {
                    title: "Credentials",
                    key: "credentials",
                    render: (_, record) => (
                      <>
                        <div>User ID: {record.credentials?.user_id || "-"}</div>
                        <div>
                          Password: {record.credentials?.password || "-"}
                        </div>
                      </>
                    ),
                  },
                  {
                    title: "Assignee",
                    dataIndex: ["assignee_details", "employee_name"],
                    key: "assignee",
                    render: (name) => name || "-",
                  },
                ]}
                pagination={false}
                rowKey="id"
                bordered
                scroll={{ y: 400 }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowTaskModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default HRApproveLeaves;
